import { slugify, truncate } from 'underscore.string';
import { ReactNode } from 'react';
import PostLink from 'src/modules/shared/links/postLink';
import Avatar, { Size } from 'src/components/avatar';
import Card from 'src/components/card';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { useSelector } from 'src/store/store';
import { generateAvatarLinkFromHash } from 'src/utils/linkUtils';
import { PinnedPost, PopularPost, PostType } from 'src/constants/types';
type Props = {
  posts: PinnedPost[] | PopularPost[];
  postType: 'pinned-post' | 'popular-post';
  seeAllLink?: ReactNode;
  title: string;
};
export default function PostListCard({
  posts,
  title,
  seeAllLink,
  postType
}: Props) {
  const communitySlug = useSelector(({
    context
  }) => context.currentCommunity?.slug);
  if (!communitySlug || posts.length === 0) return null;
  return <Card seeAllLink={seeAllLink} title={title} data-sentry-element="Card" data-sentry-component="PostListCard" data-sentry-source-file="postListCard.tsx">
      {posts.map(post => <PostLink communitySlug={communitySlug} isPrivate={false} key={post.postId} postId={post.postId} postTypeId={'postTypeId' in post ? post.postTypeId : PostType.Post} urlEncodedTitle={'urlEncodedTitle' in post && post.urlEncodedTitle ? post.urlEncodedTitle : slugify(post.title)} onClick={() => {
      trackFootfallEvent(Event.Clicked, {
        clickType: 'post-side-bar',
        clickMetadata: {
          section: Section.PostsSideBar,
          element: postType
        }
      });
    }}>
          <div className={`community__widget__${postType}`}>
            <div className={`community__widget__${postType}__avatar`}>
              <Avatar size={Size.Small} url={'avatar' in post && post.avatar ? post.avatar : generateAvatarLinkFromHash(post.userImageHash)} />
            </div>
            <div className={`community__widget__${postType}__title`}>
              {truncate(post.title, 60)}
            </div>
          </div>
        </PostLink>)}
    </Card>;
}