// @ts-strict-ignore
import styled from 'styled-components';
import i18n from 'src/utils/translate';
import Avatar, { Size } from 'src/components/avatar';
import Button from 'src/components/buttons/button';
import { WriteLink } from 'src/modules/community/shared/components/writeButton';
import { Section } from 'src/constants/footfall';
import { useSelector } from 'src/store/store';
import { hideWriteCTA } from 'src/constants/restrictedCommunities';
import { titleMedium, titleSmall } from 'styles-js/mixins/typography';
export default function CommunityFooterCTA(): JSX.Element {
  const {
    currentCommunity,
    currentUser
  } = useSelector(({
    context
  }) => context);
  const isWriteCTAvisible = !hideWriteCTA.includes(currentCommunity?.slug) && !currentCommunity.isArchived;
  if (!isWriteCTAvisible) return null;
  return <WriteLink trackingSection={Section.CommunityHome} data-sentry-element="WriteLink" data-sentry-component="CommunityFooterCTA" data-sentry-source-file="communityFooterCTA.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="communityFooterCTA.tsx">
        {currentUser && <Avatar alt="Your avatar" hash={currentUser.userImageHash} size={Size.Small} />}
        <span>{i18n.t('Write a post or ask a question')}</span>
        <Button icon="pencil" data-sentry-element="Button" data-sentry-source-file="communityFooterCTA.tsx">
          <span className="hidden-xs">{i18n.t('Write')}</span>
        </Button>
      </Container>
    </WriteLink>;
}
const Container = styled.div`
  ${titleMedium}
  border: ${({
  theme
}) => theme.greyLine};
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    ${titleSmall}
    border-left: 0;
    border-right: 0;
    padding: 5px 15px;
    img {
      display: none;
    }
  }

  span {
    flex: 1;
    transition: all 0.5s ease;
  }

  &:hover, &:focus, &:active {
    color: ${({
  theme
}) => theme.colorGreyDark};
  }
`;