import Link from 'next/link';
import i18n from 'src/utils/translate';
import PostListCard from 'src/modules/community/shared/components/sidebar/postListCard';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { useSelector } from 'src/store/store';
import Icon from 'src/components/icon';
import { PinnedPost } from 'src/constants/types';
type Props = {
  posts: PinnedPost[];
};
export default function PinnedPosts({
  posts = []
}: Props) {
  if (posts.length === 0) return null;
  return <PostListCard posts={posts} postType={'pinned-post'} seeAllLink={<SeeAllLink />} title={i18n.t('Pinned Posts')} data-sentry-element="PostListCard" data-sentry-component="PinnedPosts" data-sentry-source-file="pinnedPosts.tsx" />;
}
function SeeAllLink() {
  const communitySlug = useSelector(({
    context
  }) => context.currentCommunity?.slug);
  return <Link as={`/${communitySlug}/posts#pinned`} href={`/community/posts?communitySlug=${communitySlug}`} onClick={() => {
    trackFootfallEvent(Event.Clicked, {
      clickType: 'see-all-pinned-posts',
      clickMetadata: {
        section: Section.PostsSideBar
      }
    });
  }} data-sentry-element="Link" data-sentry-component="SeeAllLink" data-sentry-source-file="pinnedPosts.tsx">
      {i18n.t('See all')} <Icon icon="open-right" data-sentry-element="Icon" data-sentry-source-file="pinnedPosts.tsx" />
    </Link>;
}